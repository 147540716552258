/**
 * This is the address bar that displays the current activeLocation.
 * Tapping should open up the address select modal.
 */

import { Fragment, PureComponent } from 'react'

import styled from '@emotion/styled'

import { breakpoint } from '@/theme'
import { TEST_IDS } from '@components/address/test/constants'

import { bool, object } from 'prop-types'

export default class AddressDisplay extends PureComponent {
  static propTypes = {
    loading: bool,
    location: object
  }

  render() {
    const { loading, location } = this.props

    const address = location && location.activeLocation
    if (!address) return null

    return (
      <AddressContainer className="fs-mask" data-e2eid={TEST_IDS.ADDRESS_PICKER}>
        {loading ? 'Updating location...' : <Fragment>{title(address, location.inSampleLocation)}</Fragment>}
      </AddressContainer>
    )
  }
}

// Takes in an address object returned by Google and returns the correct address
const CITY_ADDRESS_TYPES = ['locality', 'political']

function title(address, inSampleLocation) {
  if (inSampleLocation) {
    return 'Please enter your address'
  }

  if (!address.city && address.zip) {
    return address.zip
  }

  // if the address has a city type, which means its types array has one or member of the city_address_types,
  // then display the city name
  if (address.types && address.types.some((type) => CITY_ADDRESS_TYPES.includes(type))) {
    return address.city
  } else {
    return address.title ? address.title : 'Please enter your address'
  }
}

const AddressContainer = styled.div`
  width: 100%;
  padding-left: 16px;
  @media (min-width: ${breakpoint.min.lg}px) {
    padding-left: 10px;
  }
}

`
