import { Fragment } from 'react'

import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { TEST_IDS } from '@/components/navbar/test/constants'
import { toggleAddress } from '@/redux/addressModal/actions'
import { logout } from '@/redux/app/actions'
import { isLoggedIn as getIsLoggedIn } from '@/redux/auth/selectors'
import { goToCartDrawer } from '@/redux/cart/actions'
import { getCartCount } from '@/redux/cart/selectors'
import { toggleSidebar } from '@/redux/menu/actions'
import { toggleSearchDrawer } from '@/redux/search/actions'
import { Button } from '@/stories/buttons/button'
import AddressDisplay from '@/stories/modals/address/display'
import { breakpoint } from '@/theme'
import {
  BagEmptyIcon,
  BagFilledIcon,
  LeftArrowIcon,
  LocationIcon,
  MenuIcon,
  SearchIcon
} from '@microcomponents/icons/new'
import { track } from 'analytics'
import Logo from 'components/logo'
import ROUTES from 'helpers/routes'

import PropTypes from 'prop-types'

const AddressModal = dynamic(import('@/stories/modals/address'), {
  loading: () => null,
  ssr: false
})

const Sidebar = dynamic(import('@/stories/sidebar'), {
  loading: () => null,
  ssr: false
})

const SearchDrawer = dynamic(import('@/stories/search'), {
  loading: () => null,
  ssr: false
})

if (typeof window !== 'undefined') {
  require('intersection-observer') // needed for safari
}

const Navbar = ({
  backButtonRoute,
  hasBanner,
  hideAddress = false,
  hideSidebarButton = false,
  showBackButton = false,
  showLoginShopButtons = false,
  showSupportButton = false,
  noCheckout = false
}) => {
  const theme = useTheme()
  const router = useRouter()
  const dispatch = useDispatch()

  const cartCount = useSelector(getCartCount)
  const isLoggedIn = useSelector(getIsLoggedIn)

  const handleBackButton = () => {
    router.push(backButtonRoute || ROUTES.ROOT)
  }

  const handleSearchButtonClick = () => {
    dispatch(toggleSearchDrawer())
    track('Search.View')
  }

  const showCart = cartCount !== 0

  return (
    <Fragment key={isLoggedIn}>
      <NavbarSpacerDiv hideAddress={hideAddress}>
        <Nav data-e2eid={TEST_IDS.COMPONENT} hasBanner={hasBanner}>
          {!hideAddress && (
            <MobileAddressDisplayLayout onClick={() => dispatch(toggleAddress())}>
              <LocationPinContainer>
                <LocationPin color={theme.colors.black} />
              </LocationPinContainer>
              <AddressDisplay />
            </MobileAddressDisplayLayout>
          )}
          <Container>
            <LeftNav>
              {!hideSidebarButton && (
                <SidebarButton
                  data-e2eid={showBackButton ? TEST_IDS.BACK_BUTTON : TEST_IDS.TOGGLE_SIDEBAR_BUTTON}
                  onClick={showBackButton ? handleBackButton : () => dispatch(toggleSidebar())}>
                  {showBackButton ? (
                    <LeftArrowIcon color={theme.colors.black} />
                  ) : (
                    <MenuIcon color={theme.colors.black} />
                  )}
                </SidebarButton>
              )}
              {!hideAddress && (
                <AddressDisplayLayout onClick={() => dispatch(toggleAddress())}>
                  <LocationPin color={theme.colors.black} />
                  <AddressDisplay />
                </AddressDisplayLayout>
              )}
            </LeftNav>
            <CenterNav>
              <Link href={ROUTES.ROOT}>
                <StyledLogo color={theme.colors.black} />
              </Link>
            </CenterNav>
            <ButtonGroup>
              {showLoginShopButtons && (
                <Fragment>
                  {!isLoggedIn ? (
                    <Link data-e2eid={TEST_IDS.LOGIN_LINK} href={ROUTES.LOGIN}>
                      <Login showCart={showCart}>Log In</Login>
                    </Link>
                  ) : (
                    <LogoutButton data-e2eid={TEST_IDS.LOGIN_LINK} onClick={() => dispatch(logout(ROUTES.LOGIN))}>
                      <Login showCart={showCart}>Log Out</Login>
                    </LogoutButton>
                  )}
                  <Link href={ROUTES.MENU}>
                    <Shop showCart={showCart}>Shop</Shop>
                  </Link>
                </Fragment>
              )}
              {!showLoginShopButtons && !noCheckout && (
                <Fragment>
                  <SearchButton
                    data-e2eid={TEST_IDS.SEARCH_BUTTON}
                    onClick={handleSearchButtonClick}
                    showCart={showCart}>
                    <SearchButtonIcon />
                  </SearchButton>
                  <ButtonCart
                    data-e2eid={TEST_IDS.OPEN_CART_BUTTON}
                    onClick={() => dispatch(goToCartDrawer())}
                    show={showCart}
                    cartCount={cartCount}>
                    {cartCount > 0 ? (
                      <Fragment>
                        <BagFilledIcon color={theme.colors.black} />
                        <CartCount>{cartCount}</CartCount>
                      </Fragment>
                    ) : (
                      <BagEmptyIcon color={theme.colors.black} />
                    )}
                  </ButtonCart>
                </Fragment>
              )}
              {showSupportButton && (
                <HelpButton
                  href="https://eaze.zendesk.com/hc/en-us/requests/new"
                  rel="noopener noreferrer"
                  target="_blank">
                  <picture>
                    <source srcSet="/static/icons/question-mark.svg" type="image/svg" />
                    <img src="/static/icons/question-mark.svg" alt="" />
                  </picture>
                </HelpButton>
              )}
            </ButtonGroup>
          </Container>
        </Nav>
      </NavbarSpacerDiv>
      <Sidebar />
      <AddressModal />
      <SearchDrawer />
    </Fragment>
  )
}

Navbar.propTypes = {
  backButtonRoute: PropTypes.string,
  hasBanner: PropTypes.bool,
  hideAddress: PropTypes.bool,
  hideSidebarButton: PropTypes.bool,
  showBackButton: PropTypes.bool,
  showLoginShopButtons: PropTypes.bool,
  showSupportButton: PropTypes.bool,
  noCheckout: PropTypes.bool
}

export default Navbar

const LogoutButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: 0;
  font-family: ${({ theme }) => theme.typography.font.simBold};
  font-size: 12px;
  @media (max-width: ${breakpoint.max.lg}px) {
    font-size: 11px;
  }
`

const LocationPinContainer = styled.div`
  display: flex;
  border-right: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  padding: 20px 30px;
  @media (max-width: ${breakpoint.max.lg}px) {
    padding: 15px;
  }
`

const LeftNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  @media (max-width: ${breakpoint.max.lg}px) {
    border-right: 0;
  }
`

const LeftArrow = (props) => (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g
      transform="translate(15.500000, 15.000000) scale(-1, 1) translate(-15.500000, -15.000000) translate(5.000000, 6.000000)"
      stroke={props.color}
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round">
      <path d="M12 0l9 9M0 9h21m0 0l-9 8.485" />
    </g>
  </svg>
)

LeftArrow.propTypes = {
  color: PropTypes.string
}

const Burger = (props) => (
  <svg width="20px" height="14px" viewBox="0 0 16 14" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g transform="translate(-21.000000, -23.000000)">
      <g
        id="Menu"
        transform="translate(21.000000, 23.000000)"
        strokeWidth="1.5"
        stroke={props.color}
        strokeLinecap="square"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="square">
        <line x1="0.769230769" y1="1" x2="19.2307692" y2="1" id="Line" />
        <line x1="0.769230769" y1="7" x2="19.2307692" y2="7" id="Line-Copy" />
        <line x1="0.769230769" y1="13" x2="19.2307692" y2="13" id="Line-Copy-2" />
      </g>
    </g>
  </svg>
)

Burger.propTypes = {
  color: PropTypes.string
}

const CartCount = styled.span`
  position: absolute;
  top: calc(50% + 2px);
  left: 50%;
  transform: translate(-50%, -50%);
`

const CenterNav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};

  svg {
    width: 60px;
  }

  @media (max-width: ${breakpoint.max.lg}px) {
    border-right: 0;
  }
`

const HelpButton = styled.a`
  cursor: pointer;
  background-color: transparent;
  padding: 20px 30px;
  border-left: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  max-height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    max-height: 55px;
    padding: 15px;
  }
`

const StyledLogo = styled(Logo)`
  cursor: pointer;
`

const NavbarSpacerDiv = styled.div`
  height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    height: ${({ hideAddress }) => (hideAddress ? '56px' : '111px')};
  }
`

const Nav = styled.nav`
  width: 100%;
  position: fixed;
  top: ${(props) => (props.hasBanner ? 64 : 0)}px;
  left: 0;
  z-index: 11;
  background: ${({ theme }) => theme.colors.white};
  transition: all 0.2s;

  @supports (-webkit-overflow-scrolling: touch) {
    background-color: ${({ theme }) => theme.colors.white} !important;
  }
`

const Container = styled.div`
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  ${({ hideAddress, theme }) =>
    hideAddress &&
    css`
      border-top: 1px solid ${theme.colors.backgroundDisabled};
    `}
`

const SidebarButton = styled.button`
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  border-right: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  padding: 20px 30px;
  max-height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    max-height: 55px;
    padding: 15px;
  }
`

const LocationPin = LocationIcon

LocationPin.propTypes = {
  color: PropTypes.string
}

const AddressDisplayLayout = styled.button`
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  text-align: left;
  padding: 20px 30px;
  max-height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    display: none;
  }
`

const MobileAddressDisplayLayout = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  align-items: center;
  text-align: left;
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  max-height: 55px;
  @media (min-width: ${breakpoint.min.lg}px) {
    display: none;
  }
`

const ButtonGroup = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
`

const Shop = styled(Button)`
  padding: ${({ theme }) => theme.spacing.small}px ${({ theme }) => theme.spacing.medium}px;
  margin: 0 15px;
  font-size: 12px;
  @media (max-width: ${breakpoint.max.lg}px) {
    padding: ${({ theme }) => theme.spacing.small}px;
    font-size: 11px;
    margin: 0 10px;
  }
`

const ButtonCart = styled.button`
  cursor: pointer;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.typography.size.desktop.tiny}px;
  background: ${({ theme, cartCount }) => (cartCount === 0 ? theme.colors.white : theme.colors.subYellow)};
  padding: 20px 30px;
  border-left: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  max-height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    max-height: 55px;
    padding: 15px;
  }
`

const Login = styled.div`
  text-decoration: underline;
  font-family: ${({ theme }) => theme.typography.font.simBold};
  font-size: 12px;
  border: 0px;
  cursor: pointer;
  outline: none;
  background-color: transparent;
  white-space: nowrap;
  @media (max-width: ${breakpoint.max.lg}px) {
    font-size: 11px;
  }
`

const SearchButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  padding: 20px 30px;
  border-left: 1px solid ${({ theme }) => theme.colors.backgroundDisabled};
  max-height: 64px;
  @media (max-width: ${breakpoint.max.lg}px) {
    max-height: 55px;
    padding: 15px;
  }
`

const SearchButtonIcon = SearchIcon
